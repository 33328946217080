// Import and register all your controllers from the importmap under controllers/*

// import { application } from "controllers/application"

// // Eager load all controllers defined in the import map under controllers/**/*_controller
// import { eagerLoadControllersFrom } from "@hotwired/stimulus-loading"
// eagerLoadControllersFrom("controllers", application)

// // Lazy load controllers as they appear in the DOM (remember not to preload controllers in import map!)
// // import { lazyLoadControllersFrom } from "@hotwired/stimulus-loading"
// // lazyLoadControllersFrom("controllers", application)
import { application } from "./application";

import OfficeSearchFormController from "./office_search_form_controller";
import OfficeChecklistController from "./office_checklist_controller";
import OfficeDropdownFilterController from "./office_dropdown_filter_controller";
import HistoricalLogsTaskEditFormController from './historical_logs_task_edit_form_controller';
import RequirementsOfficesController from './requirements_offices_controller';
import ReadinessOfficesController from './readiness_offices_controller';
import TaskNoteFormController from './task_note_form_controller';
import FrameworksController from './frameworks_controller';
import ScrollController from './scroll_controller';
import PeopleReadinessRequirementFormController from './people_readiness_requirement_form_controller';
import BulkEditController from './bulk_edit_controller';
import BulkEditAutocompleteController from './bulk_edit_autocomplete_controller';
import TeamController from './team_controller';
import ShowDismissalReasonController from "./task_form/show_dismissal_reason_controller";
import TaskSortController from "./task_sort_controller";
import ReadinessOfficesOverviewController from './readiness_offices_overview_controller';
import DropzoneController from './dropzone_controller';
import DropzoneSingleFileController from './dropzone_single_file_controller';
import ClipboardController from './clipboard_controller';
import ImageViewerController from './image_viewer_controller';
import TableRowClickController from './table_row_click_controller';
import TOSAgreementPopupController from './tos_agreement_popup_controller';
import TaskCheckInController from './task_check_in_controller';
import TooltipController from './tooltip_controller';
import ShowModalOnLoadController from './show_modal_on_load_controller';
import RequestFormSubmitController from './request_form_submit_controller';
import AccountMembersController from './account_members_controller';
import RequirementsRolesController from './requirements_roles_controller';
import OfficeQuickSubmitController from './office_quick_submit_controller';
import TableRowLinkController from './table_row_link_controller';
import FilesInputController from './files_input_controller';
import GenerateSopController from './generate_sop_controller';

application.register("office-search-form", OfficeSearchFormController);
application.register("office-checklist", OfficeChecklistController);
application.register("office-dropdown-filter", OfficeDropdownFilterController);
application.register("historical-logs-task-edit-form", HistoricalLogsTaskEditFormController);
application.register("requirements-offices", RequirementsOfficesController);
application.register("readiness-offices", ReadinessOfficesController);
application.register("task-note-form", TaskNoteFormController);
application.register("frameworks", FrameworksController);
application.register("scroll", ScrollController);
application.register("people-readiness-requirement-form", PeopleReadinessRequirementFormController);
application.register("bulk-edit", BulkEditController);
application.register("bulk-edit-autocomplete", BulkEditAutocompleteController);
application.register("team", TeamController);
application.register("show-dismissal-reason", ShowDismissalReasonController);
application.register("task-sort", TaskSortController);
application.register("readiness-offices-overview", ReadinessOfficesOverviewController);
application.register("dropzone", DropzoneController);
application.register("dropzone_single_file", DropzoneSingleFileController);
application.register("clipboard", ClipboardController);
application.register("image-viewer", ImageViewerController);
application.register("table-row-click", TableRowClickController);
application.register("tos-agreement-popup", TOSAgreementPopupController);
application.register("task-check-in", TaskCheckInController);
application.register("tooltip", TooltipController);
application.register("show-modal-on-load", ShowModalOnLoadController);
application.register("request-form-submit", RequestFormSubmitController);
application.register("account-members", AccountMembersController);
application.register("requirements-roles", RequirementsRolesController);
application.register("office-quick-submit", OfficeQuickSubmitController);
application.register("table-row-link", TableRowLinkController);
application.register("files-input", FilesInputController);
application.register("generate-sop", GenerateSopController);
