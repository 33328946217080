import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["editor"]
  static values = {
    url: String
  }

  generateSop(event) {
    event.preventDefault()
    
    // Get all selected requirement IDs
    const selectedIds = Array.from(document.querySelectorAll('input[name="ids[]"]:checked')).map(cb => cb.value)
    
    if (selectedIds.length === 0) {
      alert("Please select at least one requirement")
      return
    }

    // Show loading state
    const button = event.currentTarget
    button.classList.add('disabled')
    button.querySelector('span').textContent = 'Generating...'

    // Make the API call
    fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ ids: selectedIds })
    })
    .then(response => response.json())
    .then(data => {
      if (data.sop) {
        // Use the editor target to insert content
        const editor = this.editorTarget.editor
        editor.setSelectedRange([0, editor.getDocument().getLength()])
        editor.insertHTML(data.sop)
      } else {
        alert(data.error || 'Failed to generate SOP')
      }
    })
    .catch(error => {
      console.error('Error:', error)
      alert('Failed to generate SOP')
    })
    .finally(() => {
      // Reset button state
      button.classList.remove('disabled')
      button.querySelector('span').textContent = 'Generate Using AI'
    })
  }
} 